import { Injectable, OnDestroy } from '@angular/core';
import { Subject, BehaviorSubject, fromEvent } from 'rxjs';
import { takeUntil, debounceTime } from 'rxjs/operators';
import { Router } from '@angular/router';

// Menu
export interface Menu {
	headTitle1?: string,
	headTitle2?: string,
	path?: string;
	title?: string;
	icon?: string;
	type?: string;
	badgeType?: string;
	badgeValue?: string;
	active?: boolean;
	bookmark?: boolean;
	children?: Menu[];
	modulo?: number;
	seccion?: number;
}

@Injectable({
	providedIn: 'root'
})

export class NavService implements OnDestroy {

	private unsubscriber: Subject<any> = new Subject();
	public  screenWidth: BehaviorSubject<number> = new BehaviorSubject(window.innerWidth);

	// Search Box
	public search: boolean = false;

	// Language
	public language: boolean = false;
	
	// Mega Menu
	public megaMenu: boolean = false;
	public levelMenu: boolean = false;
	public megaMenuColapse: boolean = window.innerWidth < 1199 ? true : false;

	// Collapse Sidebar
	public collapseSidebar: boolean = window.innerWidth < 991 ? true : false;

	// For Horizontal Layout Mobile
	public horizontal: boolean = window.innerWidth < 991 ? false : true;

	// Full screen
	public fullScreen: boolean = false;

	constructor(private router: Router) {
		this.setScreenWidth(window.innerWidth);
		fromEvent(window, 'resize').pipe(
			debounceTime(1000),
			takeUntil(this.unsubscriber)
		).subscribe((evt: any) => {
			this.setScreenWidth(evt.target.innerWidth);
			if (evt.target.innerWidth < 991) {
				this.collapseSidebar = true;
				this.megaMenu = false;
				this.levelMenu = false;
			}
			if(evt.target.innerWidth < 1199) {
				this.megaMenuColapse = true;
			}
		});
		if(window.innerWidth < 991) { // Detect Route change sidebar close
			this.router.events.subscribe(event => { 
				this.collapseSidebar = true;
				this.megaMenu = false;
				this.levelMenu = false;
			});
		}
	}

	ngOnDestroy() {
		this.unsubscriber.next();
		this.unsubscriber.complete();
	}

	private setScreenWidth(width: number): void {
		this.screenWidth.next(width);
	}

	MENUITEMS: Menu[] = [
		{
            path: '/app/inicio',
			title: 'Inicio',
			icon: 'fa-home',
			type: 'link',
			active: false
        },
		{
			title: 'Ingresos',
			icon: 'fa-tasks',
			type: 'sub',
			active: false,
			modulo: 1,
			children: [
				{
					path: '/app/ingresos/crear',
					title: 'Crear', type: 'link',
					seccion: 1
				},
				{ 
					path: '/app/ingresos/listado',
					title: 'Listado',
					type: 'link',
					seccion: 1
				},
				{ 
					path: '/app/ingresos/temperaturas-actualizar',
					title: 'Actualizar Temperaturas',
					type: 'link',
					seccion: 2
				}
			]
		},
		{
			title: 'Informes',
			icon: 'fa-bar-chart-o',
			type: 'sub',
			active: false,
			modulo: 7,
			children: [
				{
					path: '/app/informes/por-personas',
					title: 'Por Personas', type: 'link',
					seccion: 12
				},
				{ 
					path: '/app/informes/por-vehiculos',
					title: 'Por Vehículos',
					type: 'link',
					seccion: 13
				},
				{ 
					path: '/app/informes/crecimiento-personas',
					title: 'Crecimiento por Personas',
					type: 'link',
					seccion: 14
				},
				{ 
					path: '/app/informes/crecimiento-vehiculos',
					title: 'Crecimiento por Vehículos',
					type: 'link',
					seccion: 15
				}
			]
		},
		{
            path: '/app/empresas',
			title: 'Empresas',
			icon: 'fa-building-o',
			type: 'link',
			active: false,
			seccion: 3
        },
		{
			title: 'Personas',
			icon: 'fa-male',
			type: 'sub',
			active: false,
			modulo: 3,
			children: [
				{ 
					path: '/app/clientes',
					title: 'Clientes',
					type: 'link',
					seccion: 11
				},
				{ 
					path: '/app/trabajadores',
					title: 'Trabajadores',
					type: 'link',
					seccion: 4
				},
				{ 
					path: '/app/personas-externas',
					title: 'Personas Externas',
					type: 'link',
					seccion: 5
				},
				{ 
					path: '/app/cargos',
					title: 'Cargos',
					type: 'link',
					seccion: 6
				}
			]
		},
		{
			title: 'Mantenedores',
			icon: 'fa-align-justify',
			type: 'sub',
			active: false,
			modulo: 8,
			children: [
				{ 
					path: '/app/mantenedores/vehiculos-tipos',
					title: 'Tipos de Vehículos',
					type: 'link',
					seccion: 16
				}
			]
		},
		{
			title: 'Centro de Usuarios',
			icon: 'fa-users',
			type: 'sub',
			active: false,
			modulo: 4,
			children: [
				{ 
					path: '/app/centrousuarios/usuarios',
					title: 'Usuarios',
					type: 'link',
					seccion: 7
				},
				{ 
					path: '/app/centrousuarios/bitacora',
					title: 'Bitácora',
					type: 'link',
					seccion: 8
				}
			]
		},
		{
            path: '/app/configuracion',
			title: 'Configuración',
			icon: 'fa-cog',
			type: 'link',
			active: false,
			seccion: 9
        }
	];

	

	// Array
	items = new BehaviorSubject<Menu[]>(this.MENUITEMS);

}
